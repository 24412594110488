:root {
  /* --main-bg-color: #c1dd76; */
  --main-bg-color: #96ddff;
  --primary-color: #1b2944;
  --secondary-color: #90b944;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  background: var(--main-bg-color)
    url('https://educatiepentrustiinta.ro/images/cest-pattern.2x.png') !important;
  font-family: sans-serif;
  user-select: none;
  cursor: default;
}
a:not([class*="MuiButtonBase"]), a:link:not([class*="MuiButtonBase"]), a:visited:not([class*="MuiButtonBase"]) {
  color:var(--primary-color);
}

.blogContent img{
  width: 100%;
}

.MuiDialog-paper .MuiAppBar-root {
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.MuiDialog-paper .MuiAppBar-root .MuiToolbar-root {
  align-items: baseline;
  padding: 0;
}
.MuiDialog-paper .MuiAppBar-root .MuiToolbar-root .MuiTypography-h6{
  margin: 0 !important;
}